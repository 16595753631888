<template>
  <div class="container">
    <div class="content">
      <div class="section">
        <div class="error">
          <p class="error-headline">500<span class="error-headline-sub">サーバーエラー</span></p>
          <p class="error-title">
            アクセスしようとしたページは<br />
            表示できませんでした。
          </p>
          <router-link class="btn-radius btn-main" :to="{ name:'Default' }">HOMEへ戻る</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error500',
  data: function() {
    return {
      pageName: '500',
    };
  },
};
</script>
